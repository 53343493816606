.pa-app{
  font-family: 'Open Sans';
  color: #444;
  font-size: 16px;
  background-color: white;
}

h1{
  font-size: 24px;
  font-weight: bold;
  font-family: 'Open Sans'; 
  /* letter-spacing: 2px; */
  color: #444;
}
h2{

  font-size: 18px;
  font-weight: bold;
  font-family: 'Open Sans'; 
  /* letter-spacing: 1px; */
  color: #444;
}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.pa-rsp-img{
  width:100%;
}
.pa-sub-h{
  font-size: 18px;
}


.pa-sub-h a{
  color:#444;
  font-weight: bold;
  text-decoration: underline;
}
.pa-carousel-top { 
  background-color: #1c365f;  
}
.pa-carousel-link{
  display: block;
  height: 100%;
}

.pa-app .pa-login-container{
  background-color: #2caae3;
  min-height: 200px;
  position: relative;
  top:-5px;
}

.pa-login{ 
  margin: 0px auto;
  padding: 20px 0px;
  text-align: center;
  position: relative;
}  

.pa-login h1{
  color: white;
  margin-bottom: 20px;
  margin-top:0px;
}

.pa-login h2{
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Open Sans'; 
  letter-spacing: 1px;
  margin-top:0px;
}

.pa-login input{  
  height: 53px;
  border: none;
  padding-left: 10px;
  border-radius: 16px;
  min-width: 286px;
  font-size: 19px;
  line-height: 19px;
  outline:none;
}

.pa-login-user{
  text-align: center;  
  vertical-align: top;
  padding-bottom: 8px;
}

.pa-login-password{
  text-align: center;  
  vertical-align: top;
}

.pa-login button{  
  min-width: 0px;
  background-color:#9c3 ;
  border: none;
  color: white;
  padding:4px 14px;
  font-size: 24px;
  border-radius: 16px; 
  font-family: "Open sans";
  font-weight: bold; 
  cursor: pointer;
  outline:none;
  position: relative;
  
}
.pa-login button:hover,
.pa-login button:active{
  background-color:#99cc33c7 !important;
}
.pa-login button:disabled,
.pa-login button:active:disabled{
  background-color:#ededed;
}
.pa-buttonProgress {
    color:#2caae3 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -19px;
    margin-left: -20px;
}
.pa-button-wrapper{
  position: relative;
  display: inline-block;
  margin-top: 10px;
}
.pa-login-forgot{
  text-align: left;
}
.pa-forgot-password{
  margin-top: 12px;  
  text-align: center;
  position:relative;
}
.pa-forgot-password a {
  color: white;
  font-size: 14px;
  text-decoration: underline;
}

div.busy input{
  background-color:#ededed;  
}

@media screen and (min-width: 600px) {
  .pa-login input{  
    width: 66%;
    min-width: 200px;
  }
  .pa-login-user{
    text-align: right;
    padding-right: 5px;
    vertical-align: top;
  }  
  .pa-login-password{
    text-align: left;
    padding-left: 5px;
    vertical-align: top;
  }
  .pa-login button{  
    margin-left: 5px;
    text-align: right;
  }
  .pa-forgot-password{
    width: 286px;
    margin-left:5px;
  }
  .pa-buttonProgress {    
    margin-top: -19px;
    margin-left: -16px;
  }
 .pa-button-wrapper{    
    margin-top: 0px;
    top:-2px;
  }
}

@media screen and (min-width: 960px) {
  .pa-login input{    
    width: 286px;
  }
  .pa-login button{  
    margin-left: 25px;
    text-align: right;
  }

  .pa-buttonProgress {    
      margin-top: -20px;
      margin-left: -7px;
  }
}
.pa-login input:focus {  outline:none; }
.pa-login input:hover {  outline:none;}
.pa-login input:hover:focus {  outline:none; }

.pa-loginError{
  position: absolute;
  color: red;
  top: 48px;
  width: 100%;
  text-align: center;
  left: 0;
}


.pa-register-section{
  background-color:#ededed;
  text-align: center;
  padding: 40px 0px 76px 0px;
  margin-top: -5px;;
}

.pa-register-btn {
  width:100%;
  max-width: 410px;
  height: 85px;
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
}

.pa-btn-learners{
  background-color: #9c3;  
  margin-bottom: 15px;
}

.pa-btn-educators{
  background-color: #2caae3; 
}



.pa-register-btn .pa-btn-title{
  font-size: 35px;
  color: white;
  position: relative;
  top: 5px;
  font-weight: bold;
}

.pa-register-btn.pa-btn-learners .pa-btn-title{
  top: 14px;
}
.pa-register-btn .pa-btn-sub{
  font-size: 14px;
  color: white;
  position: relative;
  top: 0px;
}

@media screen and (min-width: 600px) {
  .pa-register-btn.pa-btn-learners .pa-btn-title{
    top: 14px;
  }
}

@media screen and (min-width: 960px) {

  .pa-btn-learners {    
    margin-right: 5px;    
  }
  
  .pa-btn-educators {    
    margin-left: 5px;
  }

  .pa-register-btn {
    height: 100px;
  }

  .pa-register-btn .pa-btn-title{
    font-size: 48px;
    color: white;
    position: relative;
    top: 14px;
    font-weight: bold;
  }
  
  .pa-register-btn .pa-btn-sub{
    font-size: 14px;
    color: white;
    position: relative;
    top: 8px;
  }
}

.pa-why-section{
  padding: 40px 0px 76px 0px;
}

.pa-why-ico-container{
  text-align: center;
}
.pa-why-ico-container h2{
  margin-bottom: 10px;
}

.pa-new-section{
  background-color: #ededed;
  padding: 20px 0px 50px 0px;
}
.pa-new-container h1{
  color:#444
}

.pa-img-cnt{
  display: none;
}

.pa-new-container h1.pa-blue{
  text-align: left;
}

@media screen and (min-width: 600px) {
  .pa-img-cnt{
    display: block;
  }
  .pa-new-container h1.pa-blue{
    text-align: left;
  }
}

.pa-img-cnt{
  padding-top: 21px;
  text-align: center;
}
.pa-img-cnt img{
  width:100%;
  max-width:300px;
}
.pa-c{
  text-align: center;
}
.pa-txt-cnt{
  padding:0px 0px 0px 20px;
}
.pa-blue-divider{
  background-color: #1b365f;
  width:100%;
  height: 4px;
  margin-bottom: 1px;
}

@media screen and (min-width: 600px) {
  .pa-why-ico-container{
    text-align: center;
  }
  .pa-why-ico-container h2{
    margin-bottom: 1px;
  }
  
  .pa-new-section{
    background-color: #ededed;
    padding: 20px 0px 50px 0px;
  }
  .pa-new-container h1{
    color:#444
  }
  
  .pa-img-cnt{
    padding-top: 51px;
    padding-bottom: 51px;
  }
  .pa-c{
    text-align: center;
  }
  .pa-txt-cnt{
    padding:25px 0px 0px 40px;
  }
  
}

.pa-what-section{
  padding: 20px 0px 0px 0px;
}
.pa-ourusers-section{
  padding: 0px 0px 50px 0px;
}
h1.pa-blue {
  color: #1b90c2;
}

.pa-fb-btn-cnt{
  padding: 50px 0px;
}
.pa-fb-btn {
  background-color: #1b365f;
  width: 230px;
  height: 55px;
  color: white;
  font-weight: bold;
  display: inline-block;
  line-height: 55px;
  text-decoration:none;
  font-size: 16px;
  border-radius: 16px;
}

.pa-fb-btn:hover,
.pa-fb-btn:active{
  color: white;
  text-decoration:underline
}

.pa-fb-btn:visited{
  color: white;
}

.videocontainer h3{
  color: #1b90c2;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Open Sans';
}

.videocontainer video{
  margin: 40px 0px 0px 0px;
}

.pa-d-header{
  text-align: right;
}
.pa-d-root{
  min-height: 500px;
}

.line{
	width:100px;
	height:5px;
	margin:10px 0px
}

button.rounded {	
	min-width:140px;
	height:30px;
	border-radius: 15px;
	border: none;
	color: white;
	text-transform: none;
	font-size: 18px;
	cursor: pointer;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

button.green,
.line.green {
	background-color:#99CC33;
}
button.green:hover{
	background-color: #99CC33;
	opacity: 0.8;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
button.blue,
.line.blue {
	background-color:#2CAAE3;
}
button.orange,
.line.orange {
	background-color:#F7931E;
}

.pa-modal-content { 
  padding: 0px 20px 20px 20px;
  margin-top: -20px; 
}

.pa-modal-content .viewLeft{
  background-color: #1b375f;
  color: #fff;
  padding: 20px;
}
.pa-modal-content .viewLeft a{
  color: white;
  text-decoration: underline;
}
.pa-modal-content .viewRight{   
  padding: 0 20px 30px 20px;
  margin: -20px 0 0 0;
}

.pa-modal-content .leftHeading{
  font-size: 30px;
  font-family:Myriad-Pro-Normal,sans-serif;
}

.pa-modal-content h3 {
  font-size: 24px;
  font-family:Myriad-Pro-Normal,sans-serif;
  color: #666;
  position: relative;
  margin-bottom: 10px;
  margin-top:0px;
}

.pa-modal-content .MuiGrid-item{
  margin:6px 0px;
}

.pa-modal-content .formFooter{
  text-align: center;
  width:100%;
}

.pa-modal-content .formFooter .login{
  margin:20px 0px 0px 0px;
  color: #666;
}

.pa-modal-content .formFooter .link,
.pa-modal-content .formFooter a {
  text-transform: none;
  text-decoration: none;
  font-weight: 400;
  color: #2caae3;
  cursor: pointer;
}

.pa-modal-content .formFooter .link:hover,
.pa-modal-content .formFooter a:hover {
  text-decoration: underline;
}

.pa-modal-content .frow{
  margin-bottom: 10px;
}

.changedMind{
  margin: 20px;
}

.ako.selected,
.freeonline.selected{
  background-color: #F7931E;
  color: white;
}
.formRow{
  margin: 10px 0px;
}
.formRow.hidden{
  display: none;
}
.formRow .error{
  color: red;
}

.MuiDialog-paper[role="dialog"]{
  overflow-y: visible;
}

video{
  display: none;
}
@media screen and (min-width: 600px) {
  video{
    display: inline-block;
    text-align: center;
  }

  .videocontainer{
    width:100%;
    text-align: center;
  }
}

.TypeAheadDropDown input{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}