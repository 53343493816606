.TypeAheadDropDown{
 width: 100%;
 border: 1px solid rgba(0, 0, 0, 0.23);
 font-family: "Roboto", "Helvetica", "Arial", sans-serif;
 font-size: 14px;
 color: rgba(0, 0, 0, 0.73);
 border-radius: 4px; 
}

.TypeAheadDropDown.hasError{
	border-color: #f44336;
}
 
.TypeAheadDropDown input{
	width: 97%;
	border: none;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.1876em;
	letter-spacing: 0.00938em;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.73);
	padding: 10px 5px ;
	box-sizing: border-box;
	outline: none;
	height: 40px;
	margin-left: 9px;
}
.TypeAheadDropDown ul::before{
 content: '';
}
 
.TypeAheadDropDown ul{
list-style-type: none;
text-align: left;
margin: 0;
padding: 0;

position: absolute;
background:#f6f6f7;

width: 59%;
z-index: 100;
}
 
.TypeAheadDropDown li{
 padding: 10px 5px ;
 cursor: pointer;
}
 
.TypeAheadDropDown li:hover{
 background: lightgray;
 text-decoration: underline;
}

.TypeAheadDropDown .th-not-found{
    margin: 0;
    padding: 0;
    position: absolute;
    background: #f6f6f7;
    width: 59%;
    z-index: 100;
		color: red;
		padding: 10px;
}

.th-error{
	color:#f44336;
	margin-left: 14px;
  margin-right: 14px;
	font-size: 0.75rem;
	margin-top: 3px;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.66;
	letter-spacing: 0.03333em;
}